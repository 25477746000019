import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FeatureDecisionContext } from '../../../shared/contexts/FeatureDecisionContext';
import BackButton from '../../components/BackButton';
import CenterWrap from '../../components/CenterWrap';
import DesktopCard from '../../components/desktop/Card';
import List from '../../components/List';
import LoadingState from '../../components/LoadingState';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { PAGES, USER_PREFERENCE } from '../../constants';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Step from '../../helpers/Step';
import SupportedLocalePreferenceShape from '../../shapes/SupportedLocalePreferenceShape';

const useStyles = createUseStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '1.25rem 1.25rem 0',
  },
  title: {
    marginBottom: '1.875rem',
  },
}));

const UserPreference = ({
  canStepBackwards,
  mode,
  preferences,
  previous,
  previousStep,
  next,
  goToNextStep,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const { firstStep } = useContext(SettingsContext);
  const [{ skip, bookingWalkIn }, setSelections] = useContext(SelectionContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);

  const header = (
    <header className={classes.header}>
      <BackButton
        previous={previous}
        text={<FormattedMessage id="Ui.back" />}
        title={Step.getBackString(intl, previousStep)}
      />
    </header>
  );

  const setPreference = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    const selection = { userPreference: { id } };
    setSelections(selection);

    if (shouldUseKioskEnhancements && bookingWalkIn) {
      if (id !== USER_PREFERENCE.SPECIFIC) {
        setSelections({ user: null });
      }

      if (id === USER_PREFERENCE.SPECIFIC) {
        return;
      }
    }

    if (goToNextStep) {
      next(selection);
    }
  };

  const canGoToPrevStep = Step.canGoToPrevStep({
    step: 'staff',
    previousStep,
    firstStep,
    skip,
    canStepBackwards,
  });

  return (
    <CenterWrap data-testid="user-preference-desktop" narrow view={mode}>
      <TrackPageView identifier={PAGES.USER_PREFERENCE} />
      {canGoToPrevStep ? header : null}
      <section className={classes.content}>
        <header>
          <Typography
            classes={{ root: classes.title }}
            component="h2"
            variant="h5"
          >
            <FormattedMessage id="UserPreference.header" />
          </Typography>
        </header>
        <section>
          <List>
            {preferences.length > 0 ? (
              preferences.map((preference) => (
                <DesktopCard
                  action={setPreference}
                  data-testid={preference.testId}
                  icon
                  id={preference.id}
                  key={preference.id}
                  primary={
                    <FormattedMessage
                      id={preference.title}
                      values={preference.values || {}}
                    />
                  }
                  secondary={
                    <FormattedMessage
                      id={preference.subtitle}
                      values={preference.values || {}}
                    />
                  }
                />
              ))
            ) : (
              <LoadingState />
            )}
          </List>
        </section>
      </section>
    </CenterWrap>
  );
};

UserPreference.propTypes = {
  canStepBackwards: PropTypes.bool.isRequired,
  mode: PropTypes.number.isRequired,
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape).isRequired,
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  goToNextStep: PropTypes.bool,
};

UserPreference.defaultProps = {
  goToNextStep: false,
  previousStep: null,
};

export default UserPreference;
