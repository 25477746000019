import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Step, Steps, WithWizard, Wizard } from 'react-albus-react18';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import mode from '../../shared/helpers/Mode';
import { HEADER_HEIGHT, STEPS, SHORTCUTS, USER_PREFERENCE } from '../constants';
import { BookingProvider } from '../contexts/BookingContext';
import { FeatureContext } from '../contexts/FeatureContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { StepAbilityProvider } from '../contexts/StepAbilityContext';
import { StepContext } from '../contexts/StepContext';
import { DESKTOP, TABLET, ViewModeContext } from '../contexts/ViewModeContext';
import Item from '../helpers/Item';
import Storage from '../helpers/Storage';
import { URLtoCleanString } from '../helpers/Url';
import Sidebar from './Sidebar';
import Snackbar from './Snackbar';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    minHeight: 1,
  },
  fullPage: {
    minHeight: mode.isEmbedded()
      ? '100vh'
      : `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: mode.isEmbedded()
      ? '100vh'
      : `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    marginRight: '1.25rem',
    maxWidth: '16.5rem',
    minWidth: '16.5rem',
    position: 'sticky',
    top: mode.isEmbedded() ? 0 : HEADER_HEIGHT.DESKTOP,
    width: '16.5rem',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexBasis: 1,
  },
});

const StepPrecheck = ({ children, step }) => {
  const history = useHistory();
  const [selections, setSelections] = useContext(SelectionContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);

  // Make sure we revert the bookingWalkIn flag when going back from details
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const shouldAvoidResetingToAppt =
      searchParams.has('walk_in') && shouldUseKioskEnhancements;
    switch (step?.id) {
      case STEPS.DETAILS:
        // Do nothing
        break;
      default:
        if (selections.bookingWalkIn && !shouldAvoidResetingToAppt) {
          setSelections({ bookingWalkIn: false });
        }
        break;
    }
  }, [step?.id, setSelections, selections.bookingWalkIn]);

  return children;
};

const BookingSteps = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const mode = useContext(ViewModeContext);
  const features = useContext(FeatureContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const [steps, lockStep] = useContext(StepContext);
  const { firstStep } = useContext(SettingsContext);

  const [restored, setRestored] = useState(true);
  const restoredRef = useRef(true);
  const prevPathname = useRef(null);

  // to handle 'previous' action that happens right after an asyncronous state update,
  // the state is passed to the action itself, so that up-to-date state is saved in
  // history
  const isPrevious = useRef(false);
  const updatedState = useRef(null);

  // selections saved for each step when user enters them;
  // when the user goes back to a step, the selections will be taken from
  // this collection and saved in history to ensure that they are the same
  // as when the user first landed on that step
  const locationStates = useRef({});

  // when selections are about to be restored from history, we need to prevent a step
  // from loading before it's done; the flag is set here, because useEffect is fired
  // too late - after step is already loaded
  // the ref is used to change the flag immediately, and the state is for triggering update
  // when the value changes
  if (
    prevPathname.current !== history.location.pathname &&
    history.action === 'POP'
  ) {
    if (window.history.state?.selections) {
      setRestored(false);
      restoredRef.current = false;
    }
  }

  const skipMeetingMethodOnBackNavigation =
    selections?.service &&
    selections?.skip[STEPS.MEETING_METHODS] &&
    selections?.skip[STEPS.LOCATION] &&
    !selections?.userPreference?.id &&
    prevPathname.current === '/meeting-methods';

  prevPathname.current = history.location.pathname;

  useEffect(() => {
    /**
     * Saving selection state on the history entry when going to the next step.
     * The state is also saved in locationStates to be used when going to a previous step
     *
     * @param {{pathname: string}} location
     */
    const saveSelectionsInHistory = (location) => {
      let encoded = { ...selections };

      if (updatedState.current) {
        encoded = {
          ...encoded,
          ...updatedState.current,
        };

        updatedState.current = null;
      }

      encoded.date = encoded.date ? encoded.date.format('iso') : null;

      const state = { selections: JSON.stringify(encoded) };

      window.history.replaceState(state, null, '');
      locationStates.current[location.pathname] = state;
    };
    /**
     * Restore selections from history when using browser navigation. This ensures that
     * selections are always valid.
     */
    const restoreSelectionsFromHistory = () => {
      let prevSelections = window.history.state?.selections;

      if (prevSelections) {
        prevSelections = JSON.parse(prevSelections);
        prevSelections.date = prevSelections.date
          ? Dates.parse(prevSelections.date, Storage.get(SHORTCUTS.TIMEZONE))
          : null;

        setSelections(prevSelections);
      }
    };

    // save selections in history for the initial step
    if (Item.length(locationStates.current) === 0) {
      saveSelectionsInHistory(history.location);
    }

    return history.listen((location) => {
      if (history.action === 'PUSH') {
        let state;

        // go to a previous step
        if (isPrevious.current) {
          state = locationStates.current[location.pathname] || null;

          window.history.replaceState(state, null, '');

          return;
        }

        // go to the next step
        saveSelectionsInHistory(location);
      } else if (history.action === 'POP') {
        // handle browser back navigation when skipping method and location
        if (skipMeetingMethodOnBackNavigation) {
          selections.skip[STEPS.MEETING_METHODS] = false;
          selections.skip[STEPS.LOCATION] = false;
          setSelections({
            ...selections,
            service: null,
            meetingMethod: null,
          });

          // restore previous selections if navigating back so we can refetch services
          setRestored(true);
          restoredRef.current = true;
          history.go(-1);

          return;
        }

        restoreSelectionsFromHistory();
      }
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selections, setSelections]);

  useEffect(() => {
    setRestored(true);
    restoredRef.current = true;
  }, [selections]);

  // Update url with user preference
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const shouldAddUrlParams =
      searchParams.has('walk_in') &&
      searchParams.has('use_new_kiosk') &&
      shouldUseKioskEnhancements;

    if (
      shouldAddUrlParams &&
      selections?.userPreference?.id !== searchParams.get('preferred_lang')
    ) {
      if (
        selections?.userPreference?.id &&
        selections?.userPreference?.id !== USER_PREFERENCE.SPECIFIC &&
        selections?.userPreference?.id !== USER_PREFERENCE.RANDOM
      ) {
        searchParams.set('preferred_lang', selections?.userPreference?.id);
      } else {
        searchParams.delete('preferred_lang');
      }

      setSelections({ search: URLtoCleanString(searchParams) });
      history.push({ search: URLtoCleanString(searchParams) });
    }
  }, [
    selections?.userPreference?.id,
    shouldUseKioskEnhancements,
    history,
    setSelections,
  ]);

  const pushWithSearch = ({ id, push }) => {
    push(`${id}${search}`);
  };

  const determine = ({ replace }) => {
    let next = {};

    const conditions = {
      service: selections.service === null,
      location:
        // We are temporarily ignoring the destructuring-assignment rule explicitly.
        // There is a bug that was solved in a newer version of this plugin which
        // we will eventually be able to upgrade to once we can move off of
        // the current version of NodeJS in use.
        //
        // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
        //
        // eslint-disable-next-line react/destructuring-assignment
        features.meetingMethods && firstStep === 'service'
          ? selections.meetingMethod === null || selections.location === null
          : selections.location === null,
      meetingMethod:
        selections.meetingMethod === null ||
        (selections.location === null && firstStep === 'service'),
      times: selections.date === null && !selections.bookingWalkIn,
      staffPreference:
        selections.userPreference === null && selections.bookingWalkIn,
      details: selections.attendee === null,
    };

    if (Item.empty(selections, true)) {
      next = Item.first(steps);
    } else {
      Item.each(steps, (step) => {
        if (Item.empty(next)) {
          switch (step.id) {
            case STEPS.SERVICE:
              conditions.service && (next = step);
              break;
            case STEPS.LOCATION:
              conditions.location && (next = step);
              break;
            case STEPS.MEETING_METHODS:
              conditions.meetingMethod && (next = step);
              break;
            case STEPS.TIMES:
              conditions.times && (next = step);
              break;
            case STEPS.STAFF_PREFERENCE:
              conditions.staffPreference && (next = step);
              break;
            case STEPS.DETAILS:
              conditions.details && (next = step);
              break;
          }
        }
      });
    }

    pushWithSearch({
      id: Item.has(next, 'id') ? next.id : null,
      push: replace,
    });
  };

  return (
    <Wizard history={history} onNext={determine}>
      <section
        className={classNames(
          classes.root,
          mode === TABLET && classes.fullPage,
        )}
      >
        {mode === DESKTOP && (
          <aside className={classes.sidebar}>
            <Sidebar />
          </aside>
        )}
        <main className={classes.step}>
          {restored && restoredRef.current ? (
            <Steps>
              {steps.map((step) => (
                <Step
                  id={step.id}
                  key={step.id}
                  render={({ push }) => (
                    <WithWizard>
                      {({ step: current, steps: wizardSteps }) => {
                        const currentStep = wizardSteps.findIndex(
                          (step) => step.id === current.id,
                        );
                        const nextStep = wizardSteps[currentStep + 1];
                        const previousStep = wizardSteps[currentStep - 1];
                        const lockedStepCount = steps.filter(
                          (step) => step.locked,
                        ).length;

                        const next = (state = null) => {
                          isPrevious.current = false;
                          if (state) {
                            updatedState.current = state;
                          }

                          pushWithSearch({ id: nextStep.id, push });
                        };
                        const previous = (state = null) => {
                          isPrevious.current = true;
                          if (state) {
                            updatedState.current = state;
                          }

                          pushWithSearch({ id: previousStep.id, push });
                        };
                        const goTo = (
                          currentStepIndex,
                          withSearch = false,
                          state = null,
                        ) => {
                          isPrevious.current = currentStep > currentStepIndex;
                          if (state) {
                            updatedState.current = state;
                          }

                          const stepIndex = currentStepIndex + lockedStepCount;
                          const index = stepIndex >= 0 ? stepIndex : 0;

                          if (withSearch) {
                            pushWithSearch({
                              id: wizardSteps[index].id,
                              push,
                            });
                          } else {
                            push(wizardSteps[index].id);
                          }
                        };
                        const getStep = (stepIndex) => {
                          let index = stepIndex + lockedStepCount;
                          if (index < 0) {
                            index = 0;
                          } else if (index >= steps.length) {
                            index = steps.length - 1;
                          }

                          return steps[index];
                        };

                        return (
                          <BookingProvider goTo={goTo}>
                            <StepAbilityProvider
                              previous={previousStep}
                              steps={steps}
                            >
                              <StepPrecheck step={step}>
                                <step.component
                                  currentStep={currentStep - lockedStepCount}
                                  getStep={getStep}
                                  goTo={goTo}
                                  lockStep={lockStep}
                                  next={next}
                                  nextStep={nextStep ? nextStep.id : ''}
                                  previous={previous}
                                  previousStep={
                                    previousStep ? previousStep.id : ''
                                  }
                                  stepsCount={
                                    wizardSteps.length - lockedStepCount
                                  }
                                />
                              </StepPrecheck>
                            </StepAbilityProvider>
                          </BookingProvider>
                        );
                      }}
                    </WithWizard>
                  )}
                />
              ))}
            </Steps>
          ) : null}
        </main>
        <Snackbar mode={mode} />
      </section>
    </Wizard>
  );
};

export default BookingSteps;
