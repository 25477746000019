import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import DesktopListItem from '../components/desktop/ListItem';
import Checkmark from '../components/icons/Checkmark';
import Typography from '../components/Typography';
import { USER_PREFERENCE } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';

const useStyles = createUseStyles((theme: any) => ({
  select: {
    borderRadius: theme.borderRadius.default,
    color: theme.palette.secondary[500],
    display: 'flex',
    alignItems: 'center',
    minHeight: '1.5rem',
    padding: '0.5rem 0.75rem',
  },
  withIcon: {
    color: theme.palette.success[500],
    marginLeft: theme.leading.xxs,
    display: 'flex',
    '& svg:first-child': {
      fill: theme.palette.success[500],
      marginRight: '0.5rem',
    },
  },
}));

export const UserEndAdornment = ({
  selected = false,
}: {
  selected: boolean;
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  if (selected) {
    return (
      <div
        className={classNames(
          'successAdornment',
          classes.select,
          classes.withIcon,
        )}
      >
        <Checkmark
          altText={intl.formatMessage({
            id: 'Svg.alt_text.user_selected',
          })}
          ariaHidden
        />
        <Typography variant="button">
          <FormattedMessage id="Ui.selected" />
        </Typography>
      </div>
    );
  }

  return (
    <div className={classNames('adornment', classes.select)}>
      <Typography variant="button">
        <FormattedMessage id="Ui.select" />
      </Typography>
    </div>
  );
};

export const AnyoneAvailable = ({
  next,
}: {
  next: (_selection?: object) => void;
}) => {
  const intl = useIntl();

  const [{ user, userPreference }, setSelections] = useContext(
    SelectionContext,
  ) as any;

  const setAnyoneAvailable = () => {
    if (userPreference?.id === USER_PREFERENCE.RANDOM) {
      setSelections({ userPreference: { id: USER_PREFERENCE.SPECIFIC } });
      return;
    }

    const selection = {
      userPreference: { id: USER_PREFERENCE.RANDOM },
      user: null,
    };

    setSelections(selection);

    if (next) {
      next(selection);
    }
  };

  const label = intl.formatMessage({ id: 'UserPreference.random_title' });

  return (
    <DesktopListItem
      action={setAnyoneAvailable}
      adornment={
        <UserEndAdornment
          selected={!user && userPreference?.id === USER_PREFERENCE.RANDOM}
        />
      }
      ariaLabel={label}
      badges={null}
      id={USER_PREFERENCE.RANDOM}
      key={USER_PREFERENCE.RANDOM}
      primary={label}
    />
  );
};
