import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import {
  SHORTCUTS,
  USER_PREFERENCE,
  USER_PREFERENCE_OPTIONS,
} from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { StepAbilityContext } from '../contexts/StepAbilityContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Settings from '../helpers/Settings';
import Shortcuts from '../helpers/Shortcuts';
import { useSupportedLanguages } from '../hooks/useSupportedLanguage';
import { useTranslatedLocaleString } from '../hooks/useTranslatedLocaleString';
import DesktopUserPreference from './desktop/UserPreference';
import { WalkInUsers as DesktopWalkInUsers } from './desktop/WalkInUsers';
import MobileUserPreference from './mobile/UserPreference';
import { WalkInUsers as MobileWalkInUsers } from './mobile/WalkInUsers';

interface StaffPreferenceProps {
  currentStep: number;
}

export const StaffPreference: FC<StaffPreferenceProps> = (props) => {
  const mode = useContext(ViewModeContext);
  const { can } = useContext(StepAbilityContext);
  const [locale] = useContext(LocaleContext);
  const {
    isInitialLoading: languagesLoading,
    data: supportedLanguages = null,
  } = useSupportedLanguages();
  const { getTranslatedLocaleString } = useTranslatedLocaleString();
  const { shouldReorderSpecificStaffBeforeLanguages } = useFeatureDecisions();
  const {
    builderEnabled,
    reorderSpecificStaffBeforeLanguages,
    showStaffLanguages,
    showStaffPicker,
  } = useContext(SettingsContext);
  const [selections] = useContext(SelectionContext);
  const { location } = selections;

  const StepComponent = useMemo(
    () =>
      mode === MOBILE
        ? (MobileUserPreference as any as FC<any>)
        : (DesktopUserPreference as any as FC<any>),
    [mode],
  );

  const UsersStepComponent = useMemo(
    () =>
      mode === MOBILE
        ? (MobileWalkInUsers as any as FC<any>)
        : (DesktopWalkInUsers as any as FC<any>),
    [mode],
  );

  const hasUserCategory = Shortcuts.exists(SHORTCUTS.USER_CATEGORY);
  const staffPickerShown = Settings.shouldShowStaffPicker(
    { showStaffPicker },
    location,
  );

  const assemblePreferences = (languages) => {
    const [random, specific] = USER_PREFERENCE_OPTIONS;
    const preferences: {
      id: string;
      subtitle: string;
      title: string;
      testId: string;
    }[] = [];
    const skipStaffPreferences = !staffPickerShown || builderEnabled;

    if (hasUserCategory && skipStaffPreferences) {
      return [random, ...languages];
    }

    if (hasUserCategory && !skipStaffPreferences) {
      return [specific];
    }

    if (
      !builderEnabled &&
      showStaffLanguages &&
      (languagesLoading || supportedLanguages === null)
    ) {
      return preferences;
    }

    // "random" and "specific" options for user category shortcut are handled separately,
    // so only language options should be added
    if (languages.length > 0) {
      preferences.push(...languages);
    } else {
      preferences.push(random);
    }

    if (!skipStaffPreferences) {
      if (
        reorderSpecificStaffBeforeLanguages &&
        shouldReorderSpecificStaffBeforeLanguages
      ) {
        preferences.unshift(specific);
      } else {
        preferences.push(specific);
      }
    }

    return preferences;
  };

  const preferences = useMemo(() => {
    let languages = [];

    if (supportedLanguages?.length > 1) {
      languages = supportedLanguages?.map((language) => ({
        id: language,
        subtitle: 'UserPreference.assign_me_subtitle',
        testId: `user-preference-${language}`,
        title: 'UserPreference.assign_me_title',
        values: {
          language: getTranslatedLocaleString(language),
        },
      }));
    }

    return assemblePreferences(languages);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLanguages, locale]);

  if (
    selections?.userPreference?.id === USER_PREFERENCE.SPECIFIC ||
    selections?.userPreference?.id === USER_PREFERENCE.RANDOM
  ) {
    return (
      <UsersStepComponent
        {...props}
        canStepBackwards={can.step.backwards}
        goToNextStep
        mode={mode}
      />
    );
  }

  return (
    <StepComponent
      {...props}
      canStepBackwards={can.step.backwards}
      goToNextStep
      mode={mode}
      preferences={preferences}
    />
  );
};
