import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import { SHORTCUTS } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import Api from '../helpers/Api';
import Shortcuts from '../helpers/Shortcuts';

const makeUseSupportedLanguagesKey = ({
  locationId,
  serviceId,
  preferedLocation,
  inviteOnly,
  locale,
  region,
  locationCategory,
  userCategory,
  meetingMethod,
}: {
  locationId?: string;
  serviceId?: string;
  preferedLocation: boolean;
  inviteOnly: boolean;
  locale: string;
  region: string | null;
  locationCategory: string | null;
  userCategory: string | null;
  meetingMethod: number;
}) => [
  `locale:${locale}`,
  `meetingMethod:${meetingMethod}`,
  `userCategory:${userCategory}`,
  `locationCategory:${locationCategory}`,
  `region:${region}`,
  `inviteOnly:${inviteOnly}`,
  `preferedLocation:${preferedLocation}`,
  `serviceId:${serviceId}`,
  `locationId:${locationId}`,
];

export const useSupportedLanguages = () => {
  const [locale] = useContext(LocaleContext);
  const { shouldUseKioskEnhancements } = useFeatureDecisions();

  const [{ location, settings, userCategory, service, meetingMethod }] =
    useContext(SelectionContext);

  const locationCategory = Shortcuts.get(SHORTCUTS.LOCATION_CATEGORY);
  const preference = settings?.preferred_staff;
  const region =
    Shortcuts.exists(SHORTCUTS.REGION) && !location
      ? Shortcuts.get(SHORTCUTS.REGION)
      : null;

  const fetchLanguages = async () => {
    const result = await Api.locale(locale).languages().all({
      location,
      locationCategory,
      method: meetingMethod,
      region,
      service,
      settings,
      userCategory,
    });

    if (userCategory && preference !== null && result.length === 0) {
      return Api.locale(locale).languages().all({
        location,
        locationCategory,
        method: meetingMethod,
        region,
        service,
        settings,
      });
    }

    return result;
  };

  return useQuery({
    enabled: !!shouldUseKioskEnhancements,
    staleTime: 20 * 60 * 1000, // 20 minutes
    queryKey: makeUseSupportedLanguagesKey({
      locationId: location?.id,
      serviceId: service?.id,
      locale,
      meetingMethod,
      region,
      userCategory,
      locationCategory,
      preferedLocation: !!settings?.preferred_location,
      inviteOnly: !!settings?.invite_only_resources,
    }),
    queryFn: fetchLanguages,
  });
};
