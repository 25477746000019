export default {
    "AdditionalUsers.four_plus": "{first}, {second} и еще {number}",
    "AdditionalUsers.plus_more": "+ еще {number}",
    "AdditionalUsers.three": "{first}, {second} и {third}",
    "AdditionalUsers.two": "{first} и {second}",
    "AppointmentCancelled.email_message": "Мы отправили вам на электронную почту сообщение с подтверждением отмены.",
    "AppointmentCancelled.this_title": "Эта встреча отменена",
    "AppointmentCancelled.your_title": "Ваша встреча отменена",
    "AppointmentDetails.appointment_details": "Сведения о встрече",
    "AppointmentDetails.appointment_duration": "{start} - {end} ({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
    "AppointmentDetails.attendee": "Участник",
    "AppointmentDetails.attendees": "Участники",
    "AppointmentDetails.confirmation_number": "Номер для подтверждения",
    "AppointmentDetails.date_time": "Дата и время",
    "AppointmentDetails.join_by_phone": "Присоединиться по номеру телефона: <a>{number}<\/a>",
    "AppointmentDetails.join_call": "Присоединиться к звонку",
    "AppointmentDetails.join_the_call": "Присоединиться по предоставленной ссылке",
    "AppointmentDetails.join_with_this_link": "Присоединиться по этой ссылке",
    "AppointmentDetails.location": "Местоположение",
    "AppointmentDetails.phone_call": "Телефонный звонок",
    "AppointmentDetails.phone_number_ending_in": "Последние цифры номера телефона: {number}",
    "AppointmentDetails.pin": "PIN-код: {pin}",
    "AppointmentDetails.service": "Услуга",
    "AppointmentDetails.user": "Сотрудники",
    "AppointmentDetails.user_preference": "Предпочтительный сотрудник",
    "AppointmentDetails.video_call": "Видеозвонок",
    "AppointmentDetails.we_will_call": "Мы позвоним вам на указанный номер",
    "AppointmentDetails.we_will_call_you": "Мы позвоним вам на указанный номер",
    "AppointmentDetails.we_will_send_video_info": "Мы отправим вам инструкции по присоединению к встрече",
    "AppointmentSummary.cancel": "Отмена",
    "AppointmentSummary.default_error_title": "Возникла ошибка",
    "AppointmentSummary.location_button": "Просмотреть площадки",
    "AppointmentSummary.location_unavailable_description": "Эта площадка больше не доступна. Чтобы назначить встречу, нужно будет выбрать другую площадку.",
    "AppointmentSummary.location_unavailable_title": "Местоположение больше не доступно",
    "AppointmentSummary.modal_title": "Название",
    "AppointmentSummary.service_button": "Просмотреть услуги",
    "AppointmentSummary.service_unavailable_description": "Эта услуга больше не доступна. Посмотрите, не подойдут ли вам другие услуги, или обратитесь к нам за помощью: {phone}.",
    "AppointmentSummary.service_unavailable_title": "Услуга больше не доступна",
    "AppointmentSummary.times_button": "Подобрать время",
    "AppointmentSummary.times_unavailable_description": "{time} в {date} больше не доступно. Чтобы назначить встречу, нужно будет выбрать другое время.",
    "AppointmentSummary.times_unavailable_title": "Время больше не доступно",
    "AppointmentSummaryExpansionPanel.title": "Сводка по встрече",
    "AttendeeListItem.delete": "Удалить дополнительного участника",
    "AttendeeListItem.deleted": "{name} удален(а)",
    "AttendeeListItem.edit": "Изменить данные дополнительного участника",
    "BackButton.back": "Назад",
    "BackButton.back_results": "Вернуться к результатам",
    "BookingValidationErrorModal.default_error_title": "Возникла ошибка",
    "BookingValidationErrorModal.location_button": "Просмотреть площадки",
    "BookingValidationErrorModal.location_unavailable_description": "Эта площадка больше не доступна. Чтобы назначить встречу, нужно будет выбрать другую площадку.",
    "BookingValidationErrorModal.location_unavailable_title": "Местоположение больше не доступно",
    "BookingValidationErrorModal.modal_title": "Название",
    "BookingValidationErrorModal.service_button": "Просмотреть услуги",
    "BookingValidationErrorModal.service_unavailable_description": "Эта услуга больше не доступна. Посмотрите, не подойдут ли вам другие услуги, или обратитесь к нам за помощью: {phone}.",
    "BookingValidationErrorModal.service_unavailable_title": "Услуга больше не доступна",
    "BookingValidationErrorModal.times_button": "Подобрать время",
    "BookingValidationErrorModal.times_unavailable_description": "{time} в {date} больше не доступно. Чтобы назначить встречу, нужно будет выбрать другое время.",
    "BookingValidationErrorModal.times_unavailable_title": "Время больше не доступно",
    "CancelModal.additional_details": "Дополнительные сведения",
    "CancelModal.appointment_date": "{date} в {time}",
    "CancelModal.cancel_appointment": "Отменить встречу",
    "CancelModal.keep": "Сохранить",
    "CancelModal.message": "Невозможно аннулировать отмену встречи, запланированной на {date}.",
    "CancelModal.title": "Отмена встречи",
    "CancelModal.why_cancel_appointment": "Почему эта встреча отменяется?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "Эту встречу уже нельзя отменить.",
    "ChangesToYourAppointment.cancellation_policy_header": "Политика отмены",
    "ChangesToYourAppointment.cannot_cancel": "Эту встречу нельзя отменить.",
    "ChangesToYourAppointment.cannot_reschedule": "Эту встречу нельзя перенести.",
    "ChangesToYourAppointment.loading": "Идет загрузка...",
    "ChangesToYourAppointment.meeting_method_subtitle": "Если вы хотите принять участие во встрече иным образом, измените способ проведения встречи.",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "Если вы хотите изменить способ участия во встрече, то сделать это можно не позднее чем за {duration} до назначенного времени.",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "Изменить способ проведения встречи невозможно",
    "ChangesToYourAppointment.no_meeting_method_options": "Других вариантов нет",
    "ChangesToYourAppointment.reschedule_appointment": "Перенести встречу",
    "ChangesToYourAppointment.reschedule_header": "Перенести",
    "ChangesToYourAppointment.reschedule_no_longer_available": "Эту встречу уже нельзя перенести.",
    "ChangesToYourAppointment.reschedule_policy_details": "Если вы хотите перенести встречу, то сделать это можно не позднее чем за {duration} до назначенного времени.",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "Если вы хотите перенести встречу, это можно сделать до назначенного времени.",
    "ChangesToYourAppointment.title": "Изменения в вашей встрече",
    "ChangesToYourAppointment.update_meeting_method": "Изменить способ проведения встречи",
    "ChangesToYourAppointment.update_meeting_method_not_available": "Способ проведения этой встречи уже нельзя изменить.",
    "CheckIn.check_in": "Регистрироваться",
    "CheckIn.check_in.aria_label": "Запишитесь на прием",
    "CheckIn.confirmation.subtitle": "Мы свяжемся с вами в ближайшее время.",
    "CheckIn.confirmation.subtitle_notifiable": "Мы свяжемся с вами в ближайшее время. Мы сообщим, когда придет ваша очередь.",
    "CheckIn.confirmation.title": "Вы зарегистрированы!",
    "CheckIn.email": "Адрес электронной почты",
    "CheckIn.fetch_failed": "Сожалеем, но при поиске встречи произошла ошибка. Пожалуйста, обновите страницу и повторите попытку.",
    "CheckIn.join_line": "ПРИСОЕДИНЯЙТЕСЬ К ЛИНИИ",
    "CheckIn.join_line.aria_label": "Нажмите здесь, чтобы присоединиться к очереди, чтобы вас увидели, вместо того, чтобы записываться на прием",
    "CheckIn.join_line.title": "У вас нет назначенной встречи?",
    "CheckIn.landing_page.title": "Найдите свою встречу",
    "CheckIn.next": "Следующий",
    "CheckIn.next.aria_label": "Найдите свою встречу",
    "CheckIn.not_found.email": "Извините, но мы не смогли найти предстоящую встречу, которая соответствует указанному вами адресу электронной почты. Обратите внимание, что на встречи можно записаться только в течение 30 минут с запланированного времени начала.",
    "CheckIn.not_found.phone": "Извините, но мы не смогли найти предстоящую встречу, которая соответствует введенному вами номеру телефона. Обратите внимание, что на встречу можно записаться только в течение 30 минут с запланированного времени начала.",
    "CheckIn.phone": "Номер телефона",
    "CheckIn.preference": "Использование {preference} для поиска встречи",
    "CheckIn.subtitle": "Используйте свой адрес электронной почты или номер телефона, чтобы найти встречу",
    "CheckIn.subtitle_email_only": "Используйте свой адрес электронной почты, чтобы найти встречу",
    "CheckIn.summary.fetch_failed": "Приносим извинения, но при записи на встречу произошла ошибка. Пожалуйста, обновите страницу и повторите попытку.",
    "CheckIn.summary.sms_updates": "Получать обновления текстовых сообщений",
    "CheckIn.summary.sms_updates.dialog": "Получать обновления текстовых сообщений?",
    "CheckIn.summary.sms_updates.dialog.accept": "Да, отправляйте мне уведомления в SMS",
    "CheckIn.summary.sms_updates.dialog.deny": "Нет, зарегистрироваться без получения обновлений",
    "CheckIn.summary.sms_updates.message": "Мы будем присылать вам уведомления о том, когда придет ваша очередь на {phone}",
    "CheckIn.summary.subtitle": "Подтвердите информацию о встрече ниже и зарегистрируйтесь",
    "CheckIn.summary.title": "Подтвердить и зарегистрироваться",
    "CheckIn.title": "Найдите свою встречу",
    "CircularProgress.loading": "Загрузка",
    "Common.appointment_details": "Сведения о встрече",
    "CookieNotice.accept": "Принимаю",
    "CookieNotice.closed": "Заявление об отказе от использования файлов cookie закрыто.",
    "CookieNotice.ignore": "Игнорировать",
    "CookieNotice.learn_more": "Подробнее",
    "CookieNotice.message": "Мы используем файлы cookie, чтобы вам было максимально удобно работать с нашим сайтом.",
    "CookieNotice.title": "Отказ от использования файлов cookie",
    "CountrySelectInput.label": "Страна",
    "CountrySelectInput.most_popular": "Самое популярное",
    "CountryTimezoneModal.description": "Время будет отображаться согласно выбранному ниже часовому поясу.",
    "CountryTimezoneModal.title": "Выбор часового пояса",
    "DateTime.locale_badge_label": "Язык общения пользователя {name} — {language}",
    "DateTime.locale_badge_tooltip": "Язык общения — {language}",
    "DateTime.mobile_header": "Когда вы хотите встретиться?",
    "DateTime.no_language_preference": " (без {locale})",
    "DateTime.no_times_available_on_date": "Нет свободного времени",
    "DateTime.select_time": "Выбрать время",
    "DateTime.select_user": "Выбрать сотрудника",
    "DateTime.showing_times_for": "Показано время для",
    "DateTime.times_available_for_people_speaking": "Доступное время для {locale} в {date}",
    "DateTime.times_available_on_date": "Доступное время в {date}",
    "DateTime.timezone_header": "Время показано в {timezone}",
    "DateTime.user_mobile_header": "Когда вы хотите встретиться с сотрудником {name}?",
    "DateTime.user_schedule": "Назначьте время встречи с сотрудником {name},",
    "DateTime.who_speaks": " чей язык общения — {locale}",
    "Dates.all_day_date": "{date}, в течение дня",
    "Dates.all_day_today": "Сегодня в течение дня",
    "Dates.plural.day": "{count, plural, one {день} other {дни}}",
    "Dates.plural.month": "{count, plural, one {месяц} other {месяцы}}",
    "Dates.plural.week": "{count, plural, one {неделя} other {недели}}",
    "Dates.plural.year": "{count, plural, one { год } other { годы }}",
    "Dates.range_today": "Сегодня с {start} по {end}",
    "Dates.range_today_with_timezone": "Сегодня с {start} по {end} {timezone}",
    "Dates.today_with_timezone": "Сегодня, {date} {timezone}",
    "DesktopUsers.locale_badge_tooltip": "Язык общения — {language}",
    "Details.form_header": "Укажите свои сведения",
    "DetailsForm.add": "Добавить",
    "DetailsForm.add_attendee": "+ Добавить дополнительного участника",
    "DetailsForm.add_attendee_title": "Добавление участника",
    "DetailsForm.additional_attendees": "Дополнительные участники",
    "DetailsForm.additional_attendees_limit": "(не более {limit})",
    "DetailsForm.address": "Адрес",
    "DetailsForm.agree_tos": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software, а также {vendorPrivacy} {vendor}.",
    "DetailsForm.agree_tos_with_tos": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software, а также {vendorTerms} {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "Назначая встречу, вы принимаете {terms} и {privacy} Coconut Software, а также {vendorTerms} и {vendorPrivacy} {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Назначая встречу, вы принимаете {vendorTerms} и {vendorPrivacy} {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Назначая встречу, вы принимаете {vendor} {vendorPrivacy}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Назначая встречу, вы принимаете {vendor} {vendorTerms}.",
    "DetailsForm.appointment_policy_action": "бронирование встречи",
    "DetailsForm.cell_phone": "Номер мобильного телефона",
    "DetailsForm.cell_phone_context": "Мы позвоним вам на этот номер",
    "DetailsForm.city": "Город",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.country": "Страна",
    "DetailsForm.duplicate_attendee": "Участник с таким именем и адресом электронной почты уже существует",
    "DetailsForm.edit": "Сохранить",
    "DetailsForm.edit_attendee_title": "Изменение участника",
    "DetailsForm.email": "Электронная почта",
    "DetailsForm.explicit_compliance_agreement_label": "Принимаю",
    "DetailsForm.first_name": "Имя",
    "DetailsForm.home_phone": "Номер домашнего телефона",
    "DetailsForm.last_name": "Фамилия",
    "DetailsForm.notes": "Сведения о встрече",
    "DetailsForm.postal_code": "Почтовый индекс",
    "DetailsForm.primary_phone_context": "Мы позвоним вам на этот номер",
    "DetailsForm.privacy": "Уведомление о конфиденциальности",
    "DetailsForm.province": "Область",
    "DetailsForm.receive_sms": "Получать уведомления о встрече по SMS",
    "DetailsForm.receive_sms_required": "Мы используем эти сведения, чтобы отправлять вам важную информацию о вашей встрече",
    "DetailsForm.select_country": "Выберите страну",
    "DetailsForm.select_province": "Выберите область",
    "DetailsForm.select_state": "Выберите регион",
    "DetailsForm.sms_error_title": "Чтобы получать SMS-сообщения, снова выразите свое согласие.",
    "DetailsForm.state": "Регион",
    "DetailsForm.submit": "Далее",
    "DetailsForm.terms_of_use": "Условия эксплуатации",
    "DetailsForm.walkin_policy_action": "присоединяюсь к линии",
    "DetailsForm.work_phone": "Номер рабочего телефона",
    "Dialog.clear": "Очистить",
    "Dialog.confirm": "Подтвердить",
    "Dropzone.delete": "Удалить",
    "Dropzone.instructions": "Перетащите файлы сюда или <span>выберите файлы для отправки<\/span>",
    "Duration.days": "{duration, plural, one {# день} other {# дн.}}",
    "Duration.hours": "{duration, plural, one {# час} other {# ч}}",
    "Duration.minutes": "{duration, plural, one {# минута} other {# мин}}",
    "Duration.weeks": "{duration, plural, one {# неделя} other {# нед.}}",
    "EmptyState.contact_vendor": "Повторите попытку или свяжитесь с нами",
    "FastRoute.book_anyway": "Назначить встречу в качестве альтернативы",
    "FastRoute.skip_the_wait": "Пропустить ожидание",
    "FastRoute.title": "Запись на встречу не требуется",
    "Feedback.already_submitted": "Отзыв уже отправлен, спасибо!",
    "Feedback.appointment_review_subtitle": "Пожалуйста, расскажите нам, как вы относитесь к встрече.",
    "Feedback.appointment_review_title": "Обратная связь о встрече",
    "Feedback.close": "Закрыть раздел отзывов и предложений",
    "Feedback.error": "Перед отправкой укажите оценку",
    "Feedback.experience": "Ваш опыт",
    "Feedback.experience_error_message": "Обратная связь является обязательным полем. Пожалуйста, убедитесь, что длина превышает 144 символа.",
    "Feedback.experience_required": "Ваш опыт (обязательно)",
    "Feedback.google_description": "Для нас очень важны ваши отзывы и предложения. Мы уверены, что они будут полезны и другим нашим клиентам. Если у вас есть немного времени, чтобы оставить в Google отзыв о своем опыте работы с нами, нажмите расположенную ниже кнопку, и мы перенаправим вас на нужную страницу.",
    "Feedback.google_submit": "Оставить отзыв в Google",
    "Feedback.neutral": "Удовлетворительно",
    "Feedback.new_title": "Отзыв",
    "Feedback.rating": "Оценка (обязательно)",
    "Feedback.response_prompt": "Поделитесь с нами своим мнением о том, что можно улучшить",
    "Feedback.send": "Отправить отзыв",
    "Feedback.sending": "Отзыв отправляется",
    "Feedback.staff_review_subtitle": "Пожалуйста, расскажите нам, как наш сотрудник сделал сегодня.",
    "Feedback.staff_review_title": "Отзывы персонала",
    "Feedback.subtitle": "Ваш отзыв анонимен. Мы используем эти сведения, чтобы улучшить службу бронирования.",
    "Feedback.success": "Отзыв отправлен. Благодарим вас!",
    "Feedback.thank_you": "Спасибо за отзыв!",
    "Feedback.title": "Какие у вас впечатления от процесса назначения этой встречи?",
    "Feedback.very_dissatisfied": "Отвратительно",
    "Feedback.very_satisfied": "Отлично",
    "FetchSlots.error": "Что-то пошло не так, попробуйте позже",
    "FindAvailableDate.earliest_available": "Самый ранний доступный",
    "FindAvailableDate.finding_first_time": "Поиск первого доступного времени",
    "FindAvailableDate.finding_next_time": "Поиск следующего доступного времени",
    "FindAvailableDate.finding_time": "Поиск свободного времени",
    "FindAvailableDate.first_available_date": "Как можно скорее",
    "FindAvailableDate.next_available": "Следующий доступный",
    "FindAvailableDate.next_available_date": "Следующая доступная дата",
    "FindAvailableDate.tooltip_error": "Ошибка при получении даты.",
    "FindAvailableDate.tooltip_fetching": "Получение даты...",
    "Form.field_error.cell_phone_characters": "Пожалуйста, введите действительный номер мобильного телефона.",
    "Form.field_error.cell_phone_length": "Пожалуйста, введите действительный номер мобильного телефона длиной от 9 до 20 символов.",
    "Form.field_error.email_structure": "Пожалуйста, введите действительный адрес электронной почты.",
    "Form.field_error_max_length": "Это поле не может превышать {max} символов.",
    "Form.field_error_min_length": "Это поле не может быть меньше {min} символов.",
    "Form.field_required": "Это поле обязательно к заполнению.",
    "Form.optional": "(необязательно)",
    "GenericError.subtitle": "Страница, которую вы ищете, не найдена",
    "GenericError.title": "Что-то пошло не так",
    "Input.additional_input": "Дополнительное сообщение",
    "Invitations.verification_failed": "Указанный вами адрес электронной почты не совпадает с имеющимися у нас сведениями.",
    "Label.phone_number": "Номер телефона",
    "Landing.kiosk.hours_temp": "Мы открыты до 17:00.",
    "Landing.kiosk.subtitle": "Чем мы можем вам помочь?",
    "Landing.kiosk.title": "Добро пожаловать!",
    "Landing.kiosk.touch": "Коснитесь экрана, чтобы начать",
    "LandingChoices.book_appointment_subtitle": "Выберите конкретные дату и время для встречи",
    "LandingChoices.book_appointment_title": "Назначение встречи",
    "LandingChoices.callback_subtitle": "Провести встречу сегодня как можно скорее",
    "LandingChoices.callback_title": "Постановка в очередь",
    "LandingChoices.check_in_subtitle": "Если вы пришли на заранее назначенную встречу",
    "LandingChoices.check_in_title": "Регистрация",
    "LandingChoices.choose_another_location_subtitle": "Нажмите здесь, чтобы найти другое место",
    "LandingChoices.prefill_title": "Добро пожаловать, {name}! Что бы вы хотели{space}сделать?",
    "LandingChoices.prefill_title.service": "Добро пожаловать, {name}! Как вы хотите назначить встречу с {service}?",
    "LandingChoices.private_location": "Это место является частным",
    "LandingChoices.title": "Что бы вы хотели{space}сделать?",
    "LandingChoices.title.service": "Как вы хотите назначить встречу с {service}?",
    "LandingChoices.virtual_location": "Виртуальное местоположение",
    "LandingChoices.welcome_to": "Добро пожаловать в",
    "Languages.chinese": "Китайский",
    "Languages.english": "Английский",
    "Languages.french": "Французский",
    "Languages.korean": "Корейский",
    "Languages.language": "Язык",
    "Languages.polish": "польский",
    "Languages.portuguese": "португальский",
    "Languages.russian": "Русский",
    "Languages.spanish": "Испанский",
    "LobbyBanner.button": "Встать в очередь",
    "LobbyBanner.button_description": "Нажмите здесь, чтобы присоединиться к очереди, чтобы вас увидели, вместо того, чтобы записываться на прием",
    "LobbyBanner.phone_button": "Запросить обратный вызов",
    "LobbyBanner.phone_button_description": "Нажмите здесь, чтобы заказать обратный звонок вместо записи на прием",
    "LobbyBanner.title": "Запись на встречу не требуется!",
    "LobbyBanner.wait_time": "Примерное время ожидания: {time} мин",
    "Location.previously_booked": "Предварительно назначено",
    "Location.search_area": "Искать в этой области",
    "Location.select_location": "Выберите площадку",
    "Location.select_location_prefill": "{name}, выберите площадку",
    "LocationDetails.continue": "Далее",
    "LocationDetails.location_details": "Сведения о площадке",
    "LocationDetails.see_all_locations": "Просмотреть все площадки",
    "LocationDetails.select_location": "Выбрать эту площадку",
    "LocationDirectionsButton.text": "Маршрут",
    "LocationHours.closed": "Закрыто",
    "LocationHours.title": "Время работы",
    "LocationIdentifier.empty_error": "Укажите номер площадки",
    "LocationIdentifier.help_text": "Для начала укажите номер площадки, который видите на знаке",
    "LocationIdentifier.location_number": "Номер площадки",
    "LocationIdentifier.not_found_error": "Это значение не соответствует ни одной из наших площадок",
    "LocationIdentifier.title": "Добро пожаловать!",
    "LocationList.empty_state_message": "Результаты не найдены",
    "LocationList.empty_state_secondary": "Попробуйте расширить область поиска",
    "LocationList.finding_locations": "Мы ищем площадки неподалеку от вас",
    "LocationList.near_me": "Площадки неподалеку от меня",
    "LocationList.no_locations": "Площадки не найдены",
    "LocationList.title": "Выберите площадку",
    "LocationList.toggle_list": "Показать списком",
    "LocationList.toggle_map": "Показать на карте",
    "LocationPhoneCallButton.text": "Позвонить {number}",
    "LocationStepTitle.preferred_location": "Какую площадку вы предпочитаете?",
    "LocationStepTitle.preferred_location_helper": "Мы подберем сотрудника с выбранной вами площадки.",
    "LoginWithGoogle.showing_times_that_match": "Показано время, совпадающее с периодом вашей доступности",
    "LoginWithGoogle.sign_in": "Войти с помощью учетной записи Google",
    "LoginWithGoogle.sign_in_details": "чтобы увидеть время, совпадающее с периодом вашей доступности",
    "Manage.appointment_details": "Сведения о встрече",
    "Manage.book_another": "Назначить другую встречу",
    "Manage.can_never_cancel": "Управление по сети недоступно. Чтобы внести изменения, свяжитесь с нами.",
    "Manage.cancel": "Отменить встречу",
    "Manage.cancellation_policy": "Политика отмены",
    "Manage.cancellation_policy_details": "Если вы не можете принять участие во встрече, отмените ее не позднее чем за {duration} до назначенного времени.",
    "Manage.cancellation_policy_details_no_limit": "Если вы не можете принять участие во встрече, отмените ее до назначенного времени.",
    "Manage.cancelled": "Встреча отменена",
    "Manage.cancelled_successfully": "Встреча отменена",
    "Manage.cannot_cancel_anymore": "В настоящее время управление по сети недоступно. Чтобы внести изменения, свяжитесь с нами.",
    "Manage.confirmation": "Подтверждение",
    "Manage.confirmation_email": "Мы отправили вам электронное сообщение со сведениями о встрече, а также всей связанной с ней дополнительной информацией.",
    "Manage.details": "Сведения",
    "Manage.in_the_past": "Время встречи прошло",
    "Manage.reschedule": "Перенести",
    "Manage.restart": "Начать заново с домашней страницы",
    "Manage.successfully_booked": "Встреча успешно назначена",
    "Manage.updated_successfully": "Встреча обновлена",
    "ManageAppointmentDetails.details_below": "Сведения о встрече указаны ниже. Мы отправили вам копию по электронной почте.",
    "ManageAppointmentDetails.done_title": "Готово!",
    "ManageAppointmentDetails.successfully_booked": "Ваша встреча назначена.",
    "ManageAppointmentDetails.successfully_rescheduled": "Ваша встреча перенесена.",
    "ManageAppointmentDetails.your_appointment": "Ваша встреча",
    "MeetingMethodForm.appointment_date": "{date} в {time}",
    "MeetingMethodForm.keep": "Сохранить",
    "MeetingMethodForm.message.in_person": "Чтобы встретиться лично, измените способ проведения встречи, назначенной на {date}.",
    "MeetingMethodForm.message.off_site": "Чтобы встретиться в удобном для вас месте, измените способ проведения встречи, назначенной на {date}.",
    "MeetingMethodForm.message.phone_call": "Укажите телефонный звонок в качестве способа проведения встречи, назначенной на {date}.",
    "MeetingMethodForm.message.video_call": "Укажите видеозвонок в качестве способа проведения встречи, назначенной на {date}.",
    "MeetingMethodForm.new_method_label": "Как вы собираетесь участвовать во встрече?",
    "MeetingMethodForm.title": "Изменение способа проведения встречи",
    "MeetingMethodForm.update_method": "Изменить способ проведения встречи",
    "MeetingMethodInfoBanner.only_phone_available": "Эта встреча возможна только по телефону",
    "MeetingMethodInfoBanner.only_video_available": "Эта встреча возможна только по видеосвязи",
    "MeetingMethods.at_location_primary": "Лично",
    "MeetingMethods.at_location_secondary": "Выберите одну из наших площадок",
    "MeetingMethods.header": "Как вы собираетесь участвовать во встрече?",
    "MeetingMethods.off_site_primary": "Сторонняя площадка",
    "MeetingMethods.off_site_secondary": "Мы встретимся с вами",
    "MeetingMethods.phone_call_primary": "Телефон",
    "MeetingMethods.phone_call_secondary": "Мы вам позвоним",
    "MeetingMethods.video_call_primary": "Видео",
    "MeetingMethods.video_call_secondary": "Мы отправим вам ссылку",
    "MonthlyDatePicker.next_month": "Следующий месяц",
    "MonthlyDatePicker.previous_month": "Предыдущий месяц",
    "Navigation.aria_label.back": "Вернуться к предыдущему действию",
    "Navigation.aria_label.call": "Позвонить на площадку",
    "Navigation.aria_label.card": "Выбрать {navItem}",
    "Navigation.aria_label.date": "Выбрать {date}",
    "Navigation.aria_label.directions": "Просмотреть маршрут",
    "Navigation.aria_label.language": "Сотрудник, чей язык общения — {language}",
    "Navigation.aria_label.language_specific": "определенный сотрудник",
    "NoLocationPreferenceButton.skip": "Пропустить",
    "NoLocationPreferenceButton.title": "У меня нет предпочтений",
    "NoTimesAvailable.no_times_in_month": "Нет свободного времени в месяце {month}",
    "NoTimesAvailable.no_times_in_week": "На этой неделе нет свободного времени",
    "NoTimesAvailable.view_next_month": "Просмотреть следующий месяц",
    "NoTimesAvailable.view_next_week": "Просмотреть следующую неделю",
    "NotAvailable.subtitle": "В настоящее время мы не принимаем встречи",
    "NotAvailable.title": "Недоступно",
    "OtherLocationsTimeChunks.available_times_title": "Доступное время на других площадках",
    "OtherLocationsTimeChunks.no_available_times": "На других площадках нет доступного времени",
    "OtherLocationsTimeChunks.view_available_times": "Просмотреть доступное время на других площадках ",
    "OtherLocationsTimeChunks.view_more": "Показать еще",
    "Pages.country": "Страна",
    "Pages.staff_preference": "Предпочтительный сотрудник",
    "Pages.supported_language_preference": "Языки",
    "Pages.timezones": "Часовые пояса",
    "PositionErrorAlert.denied": "Мы не смогли определить ваше местоположение, так как у нас нет на это разрешения. Проверьте настройки для этого сайта в браузере.",
    "PositionErrorAlert.retry": "Повторить попытку",
    "PositionErrorAlert.unable": "Мы не смогли определить ваше местоположение.",
    "RadioButton": "Переключатель",
    "Reschedule.appointment_details": "Сведения о встрече",
    "Reschedule.confirm_time": "Подтвердить время",
    "Reschedule.error": "Встречу нельзя перенести",
    "Reschedule.label": "Подтверждение переноса встречи",
    "Reschedule.reschedule": "Перенести",
    "Security.continue": "Далее",
    "Security.email_verification": "Подтверждение адреса электронной почты",
    "Security.please_verify": "Прежде чем назначать встречу, проверьте свой адрес электронной почты.",
    "SelectInput.default": "Выбрать",
    "Service.all_categories_title": "Все категории",
    "Service.category_title": "Категории",
    "Service.five_minutes": "< 5 minutes",
    "Service.hours": "{hours, plural, one {# час} other {# ч}}",
    "Service.minutes": "{minutes, plural, one {# минута} other {# мин}}",
    "Service.mobile_header": "Какого рода помощь вам нужна?",
    "Service.no_services": "Услуги не найдены",
    "Service.read_less": "Свернуть",
    "Service.read_more": "Развернуть",
    "Service.select_title": "Выбор услуги",
    "Service.select_title_prefill": "{name},{break}выберите услугу",
    "Service.service_count": "{number, plural, one {# услуга} other {# услуг}}",
    "Service.uncategorized_title": "Другое",
    "ServiceHeader.group": "Группа",
    "ServiceInstructions.title": "Инструкции",
    "ShortcutExpired.message": "У этой ссылки истек срок действия",
    "Sidebar.footer": "На платформе",
    "Sidebar.title": "Назначение встречи",
    "Sidebar.title_check_in": "Регистрироваться",
    "Sidebar.title_join_the_line": "Присоединяйтесь к линии",
    "SpecificUserLanguagePreferences.title": "Предпочтительный язык",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "Действие {current} из {end}",
    "Steps.check_in": "Регистрироваться",
    "Steps.check_in_description": "<p> Запишитесь на прием. <\/p> <p> Мы сообщим вам, когда сотрудник будет готов вас обслужить. <\/p>",
    "Steps.date_time": "Дата и время",
    "Steps.date_time_no_wait_times": "Как только сотрудник будет готов вас обслужить",
    "Steps.date_time_with_wait_times": "Расчетное время ожидания: {time} минут",
    "Steps.date_time_with_zero_wait_times": "В данный момент времени ожидания нет",
    "Steps.details": "Сведения",
    "Steps.details_description": "<p> Добавьте себя, чтобы вас увидели сегодня. <\/p> <p> Мы сообщим вам, чтобы вы вернулись, когда сотрудник будет готов вас обслужить. <\/p>",
    "Steps.location": "Местоположение",
    "Steps.meeting_details": "Сведения о встрече",
    "Steps.meeting_methods": "Способ встречи",
    "Steps.no_staff_warning": "Встав в очередь, вас обслужит первый освободившийся сотрудник. Это может быть не {name} , которого вы ранее выбрали",
    "Steps.service": "Услуга",
    "Steps.services": "Услуги",
    "Steps.staff": "Сотрудники",
    "Steps.staff_preference": "Предпочтительный сотрудник",
    "Steps.summary": "Сводка",
    "Steps.your_details": "Ваши сведения",
    "Summary.book_appointment": "Назначение встречи",
    "Summary.header": "Почти готово! Проверка и назначение",
    "Summary.join_the_line": "Присоединяйтесь к линии",
    "Summary.please_review": "Проверьте",
    "SummaryExpansionPanel.title": "Краткое содержание",
    "SupportedLanguagePreferencePicker.anyone": "любой",
    "SupportedLanguagePreferencePicker.anyone_available": "любой, кто доступен",
    "SupportedLanguagePreferencePicker.assigned_staff": "назначенный вам сотрудник",
    "SupportedLanguagePreferencePicker.language_preference": "предпочтительный язык",
    "SupportedLanguagePreferencePicker.specific_language": "любой, чей язык общения — {language}",
    "Svg.alt_text.appointment": "Встреча",
    "Svg.alt_text.back": "Назад",
    "Svg.alt_text.back_chevron": "Назад",
    "Svg.alt_text.checkbox_not_selected": "Флажок не установлен",
    "Svg.alt_text.checkbox_selected": "Флажок установлен",
    "Svg.alt_text.checkmark": "Отметка",
    "Svg.alt_text.clock": "Часы",
    "Svg.alt_text.coconut_logo": "Логотип Coconut Software",
    "Svg.alt_text.completed": "Завершенный",
    "Svg.alt_text.continue": "Далее",
    "Svg.alt_text.country_selected": "Выбранная страна",
    "Svg.alt_text.down_chevron": "Показать еще",
    "Svg.alt_text.drop_down_arrow": "Стрелка раскрытия списка",
    "Svg.alt_text.edit": "Изменить",
    "Svg.alt_text.edit_timezone": "Изменить часовой пояс",
    "Svg.alt_text.expand": "Развернуть",
    "Svg.alt_text.expanded": "Развернуто",
    "Svg.alt_text.forward_chevron": "Вперед",
    "Svg.alt_text.hide_location_details": "Скрыть сведения о площадке",
    "Svg.alt_text.in_person": "На площадке",
    "Svg.alt_text.item_selected": "Выбранный элемент",
    "Svg.alt_text.language": "Язык",
    "Svg.alt_text.location_pin": "Местоположение",
    "Svg.alt_text.near_me": "Поблизости от меня",
    "Svg.alt_text.next": "Следующий",
    "Svg.alt_text.next_chevron": "Следующий",
    "Svg.alt_text.next_month": "Следующий месяц",
    "Svg.alt_text.next_week": "Следующая неделя",
    "Svg.alt_text.person": "Человек",
    "Svg.alt_text.phone": "Телефон",
    "Svg.alt_text.previous_month": "Предыдущий месяц",
    "Svg.alt_text.previous_week": "Предыдущая неделя",
    "Svg.alt_text.search": "Поиск",
    "Svg.alt_text.select_category": "Выбор категории",
    "Svg.alt_text.select_language": "Выбор языка",
    "Svg.alt_text.show_location_details": "Показать сведения о площадке",
    "Svg.alt_text.timezone": "Часовой пояс",
    "Svg.alt_text.timezone_selected": "Выбранный часовой пояс",
    "Svg.alt_text.up_chevron": "Показать меньше",
    "Svg.alt_text.upload": "Отправить",
    "Svg.alt_text.user_selected": "Выбранный пользователь",
    "Svg.alt_text.video": "Видео",
    "Svg.alt_text.walk_in": "Значок услуги без записи",
    "TimeChunk.no_spots": "Свободных мест не осталось",
    "TimeChunk.spots_remaining": "{number, plural, one {# свободное место} other {# свободных мест}}",
    "TimeChunk.unlimited_spots": "Неограниченное количество мест",
    "TimeChunks.afternoon": "День",
    "TimeChunks.evening": "Вечер",
    "TimeChunks.morning": "Утро",
    "TimeChunks.no_available_times": "Доступного времени нет",
    "TimeChunks.no_available_times_in_month": "В этом месяце свободных мест не найдено.",
    "TimeChunks.no_available_times_in_range": "Доступность не найдена в течение периода {range} {rangeUnit}.",
    "TimeChunks.no_available_times_in_range_month": "Доступность не найдена в течение {range} месяца.",
    "TimeChunks.no_available_times_in_range_week": "Доступность не найдена в течение {range} недели.",
    "TimeChunks.no_available_times_in_week": "На этой неделе свободных мест не обнаружено.",
    "TimeChunks.no_available_times_on": "Нет доступного времени в этот период: {date}",
    "TimeChunks.select_another_day": "Выберите другой день, чтобы найти доступное время",
    "TimeChunks.select_another_month": "Попробуйте еще месяц.",
    "TimeChunks.select_another_range": "Попробуйте другого сотрудника или местоположение.",
    "TimeChunks.select_another_week": "Попробуйте еще неделю.",
    "TimeChunks.select_user": "Выберите сотрудника, чтобы просмотреть его доступность",
    "TimezoneSelectInput.label": "Регион часового пояса",
    "TimezoneSelectInput.required": "Необходимо указать часовой пояс.",
    "TimezonesShownIn.label": "Время отображается в",
    "TooLateToCancelModal.message": "Эту встречу уже нельзя отменить по сети.",
    "TooLateToCancelModal.title": "Слишком поздно для отмены",
    "Ui.archive": "Архивировать",
    "Ui.back": "Назад",
    "Ui.back_to_previous": "Вернуться к: {previous}",
    "Ui.cancel": "Отмена",
    "Ui.clear": "Очистить",
    "Ui.close": "Закрыть",
    "Ui.confirm": "Подтвердить",
    "Ui.continue": "Далее",
    "Ui.current_selection": "Текущий выбор",
    "Ui.details_about_name": "Сведения о {name}",
    "Ui.details_about_name_and_address": "Сведения о {name}, {address}",
    "Ui.dismiss": "Закрыть",
    "Ui.do_not_continue": "Далее",
    "Ui.hours_of_operation": "Часы работы",
    "Ui.near_me": "Поблизости от меня",
    "Ui.next": "Далее",
    "Ui.next_to_previous": "Далее: {next}",
    "Ui.ok": "ОК",
    "Ui.search": "Поиск",
    "Ui.search.instructions": "Пожалуйста, начните вводить",
    "Ui.search.no_results_found": "Результаты не найдены",
    "Ui.search.plain": "Поиск",
    "Ui.select": "Выбрать",
    "Ui.select_name": "Выбрать {name}",
    "Ui.selected": "Выбрано",
    "Ui.sign_out": "Выйти",
    "Ui.type_here": "Укажите здесь...",
    "Ui.undo": "Отменить",
    "UserPreference.anyone": "Любой",
    "UserPreference.anyone_available": "Любой, кто доступен",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "Только сотрудник, чей язык общения — {language}",
    "UserPreference.assign_me_title": "Любой, чей язык общения — {language}",
    "UserPreference.assigned_title": "Назначенный вам сотрудник",
    "UserPreference.header": "Вы предпочитаете конкретного сотрудника?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "Предпочтения отсутствуют",
    "UserPreference.random_subtitle": "У меня нет предпочтений",
    "UserPreference.random_title": "Любой, кто доступен",
    "UserPreference.see_all_staff": "Просмотреть всех сотрудников",
    "UserPreference.select.header": "Выберите предпочитаемого вами сотрудника",
    "UserPreference.specific_subtitle": "Я точно знаю, с кем хочу встретиться",
    "UserPreference.specific_title": "Определенный сотрудник",
    "Validator.address": "адрес",
    "Validator.cell_phone": "номер мобильного телефона",
    "Validator.city": "город",
    "Validator.country": "страна",
    "Validator.email": "электронная почта",
    "Validator.first_name": "имя",
    "Validator.home_phone": "номер домашнего телефона",
    "Validator.last_name": "фамилия",
    "Validator.meeting_method": "способ проведения встречи",
    "Validator.not_8_consecutive_digits": "Не может содержать 8 или более последовательных цифр.",
    "Validator.not_url": "Не может содержать URL.",
    "Validator.notes": "примечания",
    "Validator.phone_code": "Неверный код страны",
    "Validator.phone_country_code": "Укажите 1–3-значный код страны со знаком «+» перед номером телефона.",
    "Validator.phone_length": "Неверная длина телефона",
    "Validator.phone_valid": "Укажите действующий номер телефона.",
    "Validator.postal_code": "почтовый индекс",
    "Validator.province": "область",
    "Validator.state": "регион",
    "Validator.work_phone": "номер рабочего телефона",
    "Validator.zip_code": "почтовый индекс",
    "ViewOnMapButton.title": "Посмотреть на карте",
    "WaitTime.loading": "Получение сведений о времени ожидания встречи без записи...",
    "WaitTime.no_walkins_currently": "В настоящее время встречи без записи не проводятся",
    "WaitTime.wait": "Текущее время ожидания встречи без записи: {wait}",
    "WaitTime.wait_time": "Текущее время ожидания встречи без записи: {waitTime}",
    "WeeklyDatePicker.next_week": "Следующая неделя",
    "WeeklyDatePicker.previous_week": "Предыдущая неделя",
    "WhatNow.homepage": "Перейти на сайт {vendor}",
    "WhatNow.title": "Что теперь?"
}